<template>
  <div class="login-content">
    <img src="https://static1.webao99.com/bj24/frontinsured/img/login_bg_a94cce29.png" class="header">
    <div class="main">
      <div class="logo">欢迎登录</div>
      <div class="form">
        <van-field v-model="form.phone" placeholder="请输入手机号" />
        <van-field v-model="form.applicant" placeholder="请输入投保人" />
        <van-checkbox v-model="checked" checked-color="#FF0904" class="checkbox">
          <template #icon="props">
            <img class="hasChoose" src="https://static1.webao99.com/bj24/frontinsured/img/checkedIcon_1993222c.png" v-if="props.checked" />
            <div v-else class="nullChoose"></div>
          </template>
        </van-checkbox>
      </div>
      <div style="margin-top: 16px;">
        <van-button round block type="danger" :disabled="!checked" native-type="submit">登录</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
      form: {
        phone: '',
        applicant: ''
      },
      activeIcon: '',
      inactiveIcon: '',
      checked: false
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.login-content {
  min-height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
  position: relative;
  .header {
    width: 750px;
  }
  .main {
    position: absolute;
    left: 35px;
    right: 35px;
    top: 240px;
    background-color: #fff;
    border-radius: 20px;
    padding: 40px 32px;
    .logo {
      font-size: 40px;
    }
    .form {
      margin-top: 50px;
      .checkbox {
        margin-top: 26px;
      }
      /deep/.van-field {
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333;
        padding-left: 0;
        border-bottom: 1px solid #ececec;
      }
      /deep/.van-cell {
        &::after {
          display: none;
        }
      }
    }
  }
  .hasChoose {
    height: 35px;
    width: 35px;
  }
  .nullChoose {
    height: 35px;
    width: 35px;
    background: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
  }
}
</style>
